import React from "react"
import {
  DataGrid,
  deDE,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid"
import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import { date, day, time } from "../components/dateFn"
import { navigate } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import UebersichtAccorion from "../components/UebersichtAccorion"
import { graphql } from "gatsby"
import { now, timestampUnix } from "../components/dateFn"
import OneColSection from "../components/OneColSection"

//

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  deDE
)

function Uebersicht(props) {
  //

  const activitiesMainStage = props.data.prismic.activities_main_stage.edges
  const activitiesSideStage = props.data.prismic.activities_side_stage.edges
  const activitiesDachboden = props.data.prismic.activities_dachboden.edges
  const activitiesInNatur = props.data.prismic.activities_in_natur.edges
  const activitiesLunaSol = props.data.prismic.activities_luna_sol.edges
  const activitiesOpenSpace = props.data.prismic.activities_open_space.edges
  const avaActivities = props.data.prismic.ava.edges
  const elaActivities = props.data.prismic.ela.edges
  const terraActivities = props.data.prismic.terra.edges
  const solYLunaActivities = props.data.prismic.solyluna.edges
  const kinderzeltActivities = props.data.prismic.kinderzelt.edges
  const concertsMs = props.data.prismic.concerts_ms.edges
  const concertsSs = props.data.prismic.concerts_ss.edges
  const ceremonies = props.data.prismic.ceremonies.edges
  const families = props.data.prismic.families.edges

  let events = [
    ...activitiesMainStage,
    ...activitiesDachboden,
    ...activitiesInNatur,
    ...activitiesLunaSol,
    ...activitiesOpenSpace,
    ...activitiesSideStage,
    ...concertsMs,
    ...concertsSs,
    ...ceremonies,
    ...families,
    ...avaActivities,
    ...elaActivities,
    ...terraActivities,
    ...solYLunaActivities,
    ...kinderzeltActivities
  ]

  //

  // sort events by the beginning date of event
  events = events.sort((a, b) => {
    // Convert the timestamps dates from strings to date objects.
    if (!a.node.body[0].primary || !b.node.body[0].primary) return 0
    let da = new Date(a.node.body[0].primary.date_time),
      db = new Date(b.node.body[0].primary.date_time)
    return da - db
  })

  //

  let futureEvents = []

  let rows = []

  for (let index = 0; index < events.length; index++) {
    const event = events[index]
    if (!event.node.body[0].primary) continue
    //Format Dates
    event.node.body[0].primary.day = day(event.node.body[0].primary.date_time)

    if (event.node.body[0].primary.end_date) {
      event.node.body[0].primary.endDay =
        " - " + day(event.node.body[0].primary.end_date)
    }

    event.node.body[0].primary.date = date(event.node.body[0].primary.date_time)
    event.node.body[0].primary.startTime = time(
      event.node.body[0].primary.date_time
    )
    event.node.body[0].primary.endTime = time(
      event.node.body[0].primary.end_time
    )

    // pass some values down the query tree
    event.node.body[0].primary.id = event.node._meta.uid
    event.node.body[0].primary.title = event.node.page_title[0].text

    //get only concerts in the future
    const endTimeUnix = timestampUnix(event.node.body[0].primary.end_time)

    if (endTimeUnix > now) {
      futureEvents.push(event.node.body[0].primary)
      //
    }
    rows = futureEvents
  }

  function getDays(params) {
    return `${params.row.day || ""}${params.row.endDay || ""}`
  }

  function getTimes(params) {
    return `${params.row.startTime || ""} - ${params.row.endTime || ""}`
  }

  let columns = [
    { field: "id", hide: true },
    { field: "event_type", headerName: "Anlass", flex: 1 },
    { field: "title", headerName: "Titel", flex: 1.5 },
    { field: "date", hide: true },
    { field: "day", hide: true },
    { field: "endDay", hide: true },
    { field: "startTime", hide: true },
    { field: "endTime", hide: true },
    {
      field: "days",
      headerName: "Tag / Tage",
      sortable: false,
      flex: 1.25,
      valueGetter: getDays,
    },
    {
      field: "time",
      headerName: "Zeit",
      sortable: false,
      width: 160,
      valueGetter: getTimes,
    },
    { field: "event_place", headerName: "Ort", flex: 1 },
  ]

  return (
    <Layout>
      <PageTitle
        pageTitle={
          props.data.prismic.allProgrammuebersichts.edges[0].node.page_title
        }
      />
      <OneColSection
        content={
          props.data.prismic.allProgrammuebersichts.edges[0].node.alt_text
        }
      />

      {rows.length > 0 ? (
        <>
          <div className="d-block d-sm-none">
            <UebersichtAccorion events={futureEvents} />
          </div>

          <div
            style={{ height: 2400, width: "100%" }}
            className="d-none d-sm-block"
          >
            <ThemeProvider theme={theme}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={100}
                // link to german page as there is no translated version at the time
                // onRowClick={(GridRowParams) => { navigate(`../${GridRowParams.id}`) }}
                onRowClick={GridRowParams => {
                  navigate(`/${GridRowParams.id}`)
                }}
                components={{
                  Toolbar: CustomToolbar,
                }}
                sortModel={[
                  {
                    field: "date",
                    sort: "asc",
                  },
                  {
                    field: "startTime",
                    sort: "asc",
                  },
                ]}
              />
            </ThemeProvider>
          </div>
        </>
      ) : null}
    </Layout>
  )
}

export default Uebersicht

export const query = graphql`
  {
    prismic {
      allProgrammuebersichts(lang: "en-us") {
        edges {
          node {
            page_title
            alt_text
          }
        }
      }
      concerts_ms: allPages(
        where: {
          page_type: "Event"
          event_type: "Konzert"
          event_place: "Hauptbühne"
        }
        lang: "de-ch"
      ) {
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                }
              }
            }
          }
        }
      }
      concerts_ss: allPages(
        where: {
          page_type: "Event"
          event_type: "Konzert"
          event_place: "Nebenbühne"
        }
        lang: "de-ch"
      ) {
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                }
              }
            }
          }
        }
      }
      activities_main_stage: allPages(
        where: {
          page_type: "Event"
          event_type: "Aktivität"
          event_place: "Hauptbühne"
        }
        lang: "de-ch"
      ) {
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                }
              }
            }
          }
        }
      }
      activities_side_stage: allPages(
        where: {
          page_type: "Event"
          event_type: "Aktivität"
          event_place: "Nebenbühne"
        }
        lang: "de-ch"
      ) {
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                }
              }
            }
          }
        }
      }
      activities_open_space: allPages(
        where: {
          page_type: "Event"
          event_type: "Aktivität"
          event_place: "Open Space Zelt"
        }
        lang: "de-ch"
      ) {
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                }
              }
            }
          }
        }
      }
      activities_luna_sol: allPages(
        where: {
          page_type: "Event"
          event_type: "Aktivität"
          event_place: "Luna & Sol Zelt"
        }
        lang: "de-ch"
      ) {
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                }
              }
            }
          }
        }
      }
      activities_in_natur: allPages(
        where: {
          page_type: "Event"
          event_type: "Aktivität"
          event_place: "In der Natur"
        }
        lang: "de-ch"
      ) {
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                }
              }
            }
          }
        }
      }
      activities_dachboden: allPages(
        where: {
          page_type: "Event"
          event_type: "Aktivität"
          event_place: "Dachboden"
        }
        lang: "de-ch"
      ) {
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                }
              }
            }
          }
        }
      }
      ceremonies: allPages(
        where: { page_type: "Event", event_type: "Zeremonie" }
        lang: "de-ch"
      ) {
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                }
              }
            }
          }
        }
      }
      families: allPages(
        where: { page_type: "Event", event_type: "Kinder & Familien Programm" }
        lang: "de-ch"
        sortBy: meta_firstPublicationDate_DESC
      ) {
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                }
              }
            }
          }
        }
      }
      solyluna: allPages(
        where: {
          page_type: "Event"
          event_type: "Aktivität"
          event_place: "Sol y Luna"
        }
        lang: "de-ch"
      ) {
        totalCount
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                  image
                  event_teaser
                }
              }
            }
          }
        }
      }
      terra: allPages(
        where: {
          page_type: "Event"
          event_type: "Aktivität"
          event_place: "Terra"
        }
        lang: "de-ch"
      ) {
        totalCount
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                  image
                  event_teaser
                }
              }
            }
          }
        }
      }
      ava: allPages(
        where: {
          page_type: "Event"
          event_type: "Aktivität"
          event_place: "Ava"
        }
        lang: "de-ch"
      ) {
        totalCount
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                  image
                  event_teaser
                }
              }
            }
          }
        }
      }
      ela: allPages(
        where: {
          page_type: "Event"
          event_type: "Aktivität"
          event_place: "Ela"
        }
        lang: "de-ch"
      ) {
        totalCount
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                  image
                  event_teaser
                }
              }
            }
          }
        }
      }
      kinderzelt: allPages(
        where: {
          page_type: "Event"
          event_type: "Aktivität"
          event_place: "Kinderzelt"
        }
        lang: "de-ch"
      ) {
        totalCount
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                  image
                  event_teaser
                }
              }
            }
          }
        }
      }
    }
  }
`
